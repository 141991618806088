// for language switcher
import enFlag from '../assets/images/en-flag.png';
import deFlag from '../assets/images/de-flag.png';
import zhFlag from '../assets/images/zh-flag.png';
import inFlag from '../assets/images/in-flag.png';
import esFlag from '../assets/images/es-flag.png';


export const CONFIG = {
    //setting up the language array here
    languages: [
        { code: 'es', name: 'Spanish', nativeName: 'Español', flag: esFlag },
        { code: 'en', name: 'English', nativeName: 'English', flag: enFlag },
        { code: 'de', name: 'German', nativeName: 'Deutsch', flag: deFlag },
        { code: 'hi', name: 'Hindi', nativeName: 'हिन्दी', flag: inFlag },
        { code: 'zh', name: 'Chinese', nativeName: '汉语 / 中文', flag: zhFlag },
    ],

    // API setup here
    API_URL: process.env.REACT_APP_API_URL,
    get CSRF_TOKEN() {
        return sessionStorage.getItem('csrfToken');
    },
    get SESSION_ID() {
        return sessionStorage.getItem('sessionId');
    },
    async fetchAndStoreSession() {
        try {
            const response = await fetch(`${this.API_URL}/api/v1/auth/start`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`API call failed with status ${response.status}`);
            }
            const data = await response.json();

            // Store the CSRF token for other requests
            sessionStorage.setItem('csrfToken', data.csrfToken);
            sessionStorage.setItem('sessionId', data.additionalParameters.sessionId);
            return data;
        } catch (error) {
            console.error('Error fetching CSRF token:', error);
            throw error;
        }
    },

    async apiCall(endpoint, method, body) {
        const headers = {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': this.CSRF_TOKEN,
        };

        const options = {
            method,
            headers,
            credentials: 'include',
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(`${this.API_URL}${endpoint}`, options);
        if (!response.ok) {
            const errorDetail = {
                status: response.status,
                message: `API call to ${endpoint} failed with status ${response.status}`,
            }
            throw errorDetail;
        }

        return response;
    },

    async sendTrackingEvent({ stepId, message }) {
        const body = {
            timestamp: Date.now(),
            eventId: 7,
            sfId: "",
            clientId: "",
            sessionId: this.SESSION_ID,
            stepId,
            message,
        };

        try {
            const response = await fetch('https://sfid.trk.eco.stillfront.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error(`Tracking request failed with status ${response.status}`);
            }

            console.log('Tracking event sent:', body);
        } catch (error) {
            console.error('Failed to send tracking event:', error);
        }
    }
};
